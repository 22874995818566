<template>
  <div class="d-flex flex-column pa-2 mt-4 full-width">
    <grid
      item-min-width="300px"
      class="align-center"
      v-if="hasErrors"
    >
      <v-text-field
        v-for="(error, idx) in errors"
        :key="idx"
        class="mx-2"
        :value="error"
        readonly
      />
    </grid>
    <grid
      item-min-width="300px"
      class="align-center "
      v-else
    >
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberWorkItemDeleted')"
        :value="workItemDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberCalenderBasedRemindersDeleted')"
        :value="calenderBasedRemindersDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberCollaborationTaskDeleted')"
        :value="collaborationTaskDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberDisputeDeleted')"
        :value="disputeDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberDunningReminderDeleted')"
        :value="dunningReminderDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberInvoiceDeleted')"
        :value="invoiceDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberMsgDeleted')"
        :value="msgDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberPromiseDeleted')"
        :value="promiseDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberRunDeleted')"
        :value="runDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberTopicDeleted')"
        :value="topicDeleted"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberWorkItemTemplateDeleted')"
        :value="workItemTemplateDeleted"
        readonly
      />
    </grid>
  </div>
</template>

<script>
// import { CacheType } from '@/wasm/pkg'

export default {
  name: 'purge',
  created () {
    this.$store.commit('setModule', {
      name: this.$t('t.PurgeRun')
    })
  },
  components: {
    Grid: () => import('@/components/grid')
  },
  computed: {
    hasErrors () {
      return this.data?.errors.length
    },
    errors () {
      return this.data?.errors
    },
    workItemDeleted () {
      return this.data?.workItemDeleted
    },
    calenderBasedRemindersDeleted () {
      return this.data?.calenderBasedRemindersDeleted
    },
    collaborationTaskDeleted () {
      return this.data?.collaborationTaskDeleted
    },
    disputeDeleted () {
      return this.data?.disputeDeleted
    },
    dunningReminderDeleted () {
      return this.data?.dunningReminderDeleted
    },
    invoiceDeleted () {
      return this.data?.invoiceDeleted
    },
    msgDeleted () {
      return this.data?.msgDeleted
    },
    promiseDeleted () {
      return this.data?.promiseDeleted
    },
    runDeleted () {
      return this.data?.runDeleted
    },
    topicDeleted () {
      return this.data?.topicDeleted
    },
    workItemTemplateDeleted () {
      return this.data?.workItemTemplateDeleted
    }
  },
  data () {
    return {
      data: null
      // cacheType: CacheType.EscalationProtocolRef
    }
  },
  methods: {
  },
  async mounted () {
    const url = '/core/run/recap'
    const r = await this.$http().get(`${url}/${this.$route.params.id}`)
    this.data = r.data
  }

}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.v-text-field
  min-width 250px
</style>
